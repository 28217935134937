import React from "react";
import { Col, Row, Button } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

// styles
const Servicetitle = styled.h2`
color: ${({ theme }) => theme.colours.primary.main};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 1.4rem;
  text-align: center;
`

const Servicecontainer = styled.div`
  background-color: ${({ theme }) => theme.colours.common.white};
  padding: 25px 30px 40px 30px;
  margin-bottom: 15px;
`


const Servicedescription = styled.div`
  color: ${({ theme }) => theme.colours.common.black};
  min-height: 250px;
  text-align: left;

  p {
    line-height: 1.2;
  }

  & a {
    color: ${({ theme }) => theme.colours.primary.main};
    text-decoration: none;
    transition: ${({ theme }) => theme.animations.link};
  }
  
  & a:hover {
    color: ${({ theme }) => theme.colours.primary.main};
    font-weight: bold;
  }
  
`

const Serviceimg = styled(GatsbyImage)`
  margin: 0 auto;
`

const Servicerow = styled(Row)`
  margin-bottom: 50px;
`

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colours.common.white};
  background-color: ${({ theme }) => theme.colours.primary.main};
  border-color: #ba2117;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  padding: 5px 20px;
  text-align: center;

  &:hover, :active, .active, .focus, :focus, :not(:disabled):not(.disabled).active, :not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle  {
    color: ${({ theme }) => theme.colours.common.white};
    background-color: ${({ theme }) => theme.colours.common.black};
    border-color: ${({ theme }) => theme.colours.common.black};
    font-family: ${({ theme }) => theme.font.headings};
    text-transform: uppercase;
    padding: 5px 20px;
  }

  &:focus, :focus {
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colours.primary.light};
  }
`

const Learnlink = styled(Link)`
  display: block;
  text-align: center;
`

function Serviceslist({ children }) {
 
  const data = useStaticQuery(graphql`
  query {
    directus {
      services(filter: {status: {_eq: "Published"}}) {
        title
        slug
        seo_description
        service_image {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(
                height: 600
                width: 900
                transformOptions: {fit: COVER}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }  
  `)

  const serviceNode = data.directus.services;
 
  return (
    
    <>
        <Servicerow>
        {serviceNode.map((serviceNode, i) => 
        (
          
            <Col xs={12} md={6} lg={4} key={i}>
              <Link to={`/services/${serviceNode.slug}`}><Serviceimg image={serviceNode.service_image.imageFile.childImageSharp.gatsbyImageData} alt={serviceNode.title} objectPosition="center center" /></Link>
              <Servicecontainer>
              <Link to={`/services/${serviceNode.slug}`}><Servicetitle>{serviceNode.title}</Servicetitle></Link>
                <Servicedescription dangerouslySetInnerHTML={{
                      __html: serviceNode.seo_description,
                  }} />
                <Learnlink to={`/services/${serviceNode.slug}`}>
                  <StyledButton>Learn More</StyledButton>
                </Learnlink>
              </Servicecontainer>
            </Col>
             ))}    
        </Servicerow>    
    
  </>
  );
}

export default Serviceslist;
import React from "react"
import Pagelayout from "../layouts/page"
import Seo from "../components/seo"
import { Col, Row, Button} from "react-bootstrap"
import Serviceslist from "../components/serviceslist"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// styles
const Pageheading = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  margin: 10px 0 50px 0;
  font-weight: 400;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.font.headings};
`

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colours.common.white};
  background-color: ${({ theme }) => theme.colours.primary.main};
  border-color: #ba2117;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  padding: 5px 20px;

  &:hover, :active, .active, .focus, :focus, :not(:disabled):not(.disabled).active, :not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle  {
    color: ${({ theme }) => theme.colours.common.white};
    background-color: ${({ theme }) => theme.colours.common.black};
    border-color: ${({ theme }) => theme.colours.common.black};
    font-family: ${({ theme }) => theme.font.headings};
    text-transform: uppercase;
    padding: 5px 20px;
  }

  &:focus, :focus {
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colours.primary.light};
  }
`


function Servicespage ({ children }) {

const data = useStaticQuery(graphql`
  query {
    directus {
      settings {
        site_title
        site_description
        site_author
        site_logo {
          title
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        footer_image {
          title
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`)

  return (
    <>
    <Seo title={`${data.directus.settings.site_title} · Our Services`} description={data.directus.settings.site_description} author={data.directus.settings.site_author}  />
    <Pagelayout logo={data.directus.settings.site_logo} footerimage={data.directus.settings.footer_image}>
        <Row>
          <Col>
                <Pageheading>Our Services</Pageheading>
          </Col>
        </Row>
        <Serviceslist />

    </Pagelayout>
    </>
  )
}


export default Servicespage
